import { Button, Row } from "antd"
import { ReactNode, useEffect, useRef, useState } from "react"
import ReactQuill from "react-quill"
import {
  focusTextEditor,
  RichTextEditor,
} from "../../../../utils/components/RichTextEditor/RichTextEditor"
import "./formActivityInput.scss"

interface Props {
  comment?: string
  children?: ReactNode
  submit: (comment: string) => void
  cancel?: () => void
}

export const FormActivityInput = ({ comment: _comment, children, submit, cancel }: Props) => {
  const inputRef = useRef<ReactQuill>(null)
  const [comment, setComment] = useState<string>()

  useEffect(() => {
    setComment(_comment)
    focusTextEditor(inputRef)
  }, [_comment])

  return (
    <div className='form-activity-input'>
      <div className={`input-content ${comment === undefined ? "hidden" : ""}`}>
        <RichTextEditor
          ref={inputRef}
          value={comment ?? ""}
          onChange={(comment) => setComment(comment)}
          className='article-description-editor'
        />
        <Row className='action'>
          <Button
            type='primary'
            onClick={() => {
              comment && submit(comment)
              setComment(undefined)
            }}
          >
            Envoyer
          </Button>
          <Button
            onClick={() => {
              setComment(undefined)
              cancel && cancel()
            }}
          >
            Annuler
          </Button>
        </Row>
      </div>
      {comment === undefined && (
        <div
          className='input-children'
          onClick={() => {
            setComment("")
            // The ref cannot focus directly because ReactQuill is display-none and needs time to render
            focusTextEditor(inputRef)
          }}
        >
          {children}
        </div>
      )}
    </div>
  )
}
