import { createContext, useState } from "react"
import { ContactForm } from "../components/ContactForm/ContactForm"
import { ContactTable } from "../components/ContactTable/ContactTable"

interface IContactContext {
  id: number | undefined
  setId: (id: number | undefined) => void
}

export const ContactContext = createContext<IContactContext>({ id: undefined, setId: () => {} })

export const Contacts: React.FC = () => {
  const [showForm, setShowForm] = useState(false)
  const [selectedContactId, setSelectedContactId] = useState<number>()

  const onClose = () => {
    setShowForm(false)
    setTimeout(() => setSelectedContactId(undefined), 500)
  }

  return (
    <ContactContext.Provider value={{ id: selectedContactId, setId: setSelectedContactId }}>
      <ContactTable
        refresh={showForm}
        onNewContact={() => setShowForm(true)}
        onEditContact={(contactId) => {
          setSelectedContactId(contactId)
          setShowForm(true)
        }}
      />
      <ContactForm isOpen={showForm} onClose={onClose} onSubmit={onClose} id={selectedContactId} />
    </ContactContext.Provider>
  )
}
