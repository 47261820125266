import { forwardRef } from "react"
import ReactQuill from "react-quill"
import "./richTextEditor.scss"

interface Props {
  value: string
  onChange: (text: string) => void
  className?: string
  modules?: Record<string, unknown>
}

export const RichTextEditor = forwardRef<ReactQuill, Props>(function MyRTE(props, ref) {
  const { value, onChange, className, modules } = props

  return (
    <ReactQuill
      ref={ref}
      modules={modules}
      className={`rich-text-editor ${className}`}
      theme='snow'
      value={value}
      onChange={(text: string, delta: unknown, source: string) => {
        // Only update the comment if the change comes from the user and not the Quill API
        if (source == "user") {
          // If the only content is a <p> or <br> tag, then the content is considered empty
          if (text.replace("<p>", "").replace("</p>", "").replace("<br>", "").length > 0) {
            onChange(text)
          } else {
            onChange("")
          }
        }
      }}
    />
  )
})

export const focusTextEditor = (ref: React.RefObject<ReactQuill>) => {
  setTimeout(() => {
    ref.current?.focus()
    ref.current?.getEditor().setSelection(ref.current?.getEditor().getLength() - 1, 0)
  }, 1)
}
