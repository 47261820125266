import { Col, Row, Typography } from "antd"
import { FC, useEffect, useState } from "react"
import { ISearchContactAddress } from "../../../../../types/IContact"
import ButtonEdit from "../../../../shared/utils/components/ButtonEdit/ButtonEdit"
import AddressSelectModal from "./AddressSelectModal"
import "./addresses.scss"

interface AddressProps {
  addresses?: ISearchContactAddress[]
}

const Addresses: FC<AddressProps> = ({ addresses = [] }) => {
  const [billingAddress, setBillingAddress] = useState({} as ISearchContactAddress)
  const [deliveryAddress, setDeliveryAddress] = useState({} as ISearchContactAddress)

  useEffect(() => {
    setBillingAddress(
      addresses.find((address) => address.pal === 1) ?? ({} as ISearchContactAddress),
    )
    setDeliveryAddress(
      addresses.find((address) => address.pal === 1) ?? ({} as ISearchContactAddress),
    )
  }, [addresses])

  const [showBillingModal, setShowBillingModal] = useState(false)
  const [showDeliveryModal, setShowDeliveryModal] = useState(false)

  return (
    <>
      <Row gutter={50} justify='start' className='contact-addresses'>
        <Col className="delivery-address">
          <Typography.Text strong className='title'>
            Adresse de livraison
          </Typography.Text>
          <Row>
            <div>
              <p>{deliveryAddress?.address}</p>
              <p>{deliveryAddress?.complement}</p>
              <p>
                {deliveryAddress?.codePostal} {deliveryAddress?.ville}
              </p>
              <p>{deliveryAddress?.countryName}</p>
            </div>
            <ButtonEdit onClick={() => setShowDeliveryModal(true)} />
          </Row>
        </Col>
        <Col className="billing-address">
          <Typography.Text strong className='title'>
            Adresse de facturation
          </Typography.Text>
          <Row>
            <div>
              <p>{billingAddress?.address}</p>
              <p>{billingAddress?.complement}</p>
              <p>
                {billingAddress?.codePostal} {billingAddress?.ville}
              </p>
              <p>{billingAddress?.countryName}</p>
            </div>
            <ButtonEdit onClick={() => setShowBillingModal(true)} />
          </Row>
        </Col>
      </Row>
      <AddressSelectModal
        open={showBillingModal || showDeliveryModal}
        addresses={addresses}
        title={showBillingModal ? "adresse de facturation" : "adresse de livraison"}
        currentAddress={showBillingModal ? billingAddress : deliveryAddress}
        onClose={(address) => {
          if (address && showBillingModal) {
            setBillingAddress(address)
          } else if (address && showDeliveryModal) {
            setDeliveryAddress(address)
          }
          setShowBillingModal(false)
          setShowDeliveryModal(false)
        }}
      />
    </>
  )
}

export default Addresses
