import { UploadOutlined } from "@ant-design/icons"
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  message,
  Row,
  Typography,
  Upload,
  UploadProps,
} from "antd"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { ISearchContact } from "../../../../types/IContact"
import { IFormContentProps } from "../../../../types/IFormProps"
import { IDevisNumbering } from "../../models/IDevisNumbering"
import { DevisAPI } from "../../services/Devis.api"
import { ContactSearch } from "../ContactSearch/ContactSearch"
import DevisFormTable from "../DevisFormTable/DevisFormTable"
import { DevisNumbering } from "../Numbering/DevisNumbering"
import "./devisFormContent.scss"

const DATE_FORMAT = "DD-MM-YYYY"

export const DevisFormContent: React.FC<IFormContentProps> = ({ id: devisId, onLoad }) => {
  const isEdition = !!devisId
  const form = Form.useFormInstance()
  const [contact, setContact] = useState<ISearchContact>()

  const [numbering, setNumbering] = useState<IDevisNumbering>()

  useEffect(() => {
    setFormData()
  }, [devisId])

  const setFormData = () => {
    onLoad(true)

    getNumbering()

    if (!isEdition) {
      form.setFieldsValue({
        nom: "",
        prenom: "",
        denomination: "",
        IDEnt: "",
        numTVA: "",
        fonction: "",
        website: "",
        tags: [],
        notes: "",
      })
      return
    }

    // DevisAPI.getDevis(contactId).then((res) => {
    //   setIsContactACompany(res.data.companyType === EContactType.ENTREPRISE)
    //   form.setFieldsValue({
    //     ...res.data,
    //     tags: res.data.tags.map((tag: ITag) => tag.IDTag),
    //     companyType: res.data.companyType,
    //     IDEnt: res.data.enterprise?.entName,
    //   })
    //   onLoad(false)
    // })
  }

  const getNumbering = () => {
    DevisAPI.getDevisNumbering()
      .then((res) => {
        setNumbering(res.data)
      })
      .finally(() => {
        onLoad(false)
      })
  }

  return (
    <Row className='devis-form'>
      <Col xs={18} xl={18}>
        <ContactSearch onSelect={setContact} value={contact} label='Nom du client' />
      </Col>

      <Divider className='first-divider' />

      {numbering && numbering.counterDV === 0 && (
        <Col xs={24} xl={12}>
          <DevisNumbering numbering={numbering} onClose={() => getNumbering()} />
        </Col>
      )}

      <Col xs={24}>
        <Row>
          <Col xs={24} xl={12}>
            <Form.Item
              label='Date du devis'
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner la date du devis",
                },
              ]}
            >
              <DatePicker defaultValue={dayjs()} format={DATE_FORMAT} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={12}>
            <Form.Item
              label="Date d'expiration"
              className='expiration-date'
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner la date d'expiration du devis",
                },
              ]}
            >
              <DatePicker defaultValue={dayjs().add(1, "month")} format={DATE_FORMAT} />
            </Form.Item>
          </Col>
        </Row>
      </Col>

      <Col xs={24} xl={12}>
        <Form.Item label='Titre du devis'>
          <Input
            placeholder='Devis'
            // value={title}
            // onChange={(e) => setTitle(e.currentTarget.value)}
          />
        </Form.Item>
      </Col>

      <DevisFormTable />

      <Divider />

      <Col xs={24}>
        <Row gutter={{ xl: 20 }}>
          <Col xs={24} xl={16}>
            <Form.Item label='Informations pour le client'>
              <Input.TextArea rows={4} cols={2} />
            </Form.Item>
            <Form.Item label='Conditions générales'>
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <FileUpload />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const FileUpload = () => {
  // TODO: Implement the upload logic
  const props: UploadProps = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  return (
    <Flex className='upload-file' justify='flex-start' align='flex-end' vertical>
      <div className='upload'>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Joindre un fichier</Button>
        </Upload>
        <Typography.Text type='secondary'>
          Formats acceptés: PDF, DOCX, XLSX, JPG, PNG
          <br />
          Taille maximale: 5 Mo
        </Typography.Text>
      </div>
    </Flex>
  )
}
