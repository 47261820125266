import { Edit } from "react-flaticons"
import "./buttonEdit.scss"

interface Props {
  onClick?: () => void
}

const ButtonEdit: React.FC<Props> = ({ onClick }) => {
  return <Edit size={15} className='edit-button' onClick={onClick} />
}

export default ButtonEdit
