import { DownOutlined } from "@ant-design/icons"
import { Button, Divider, Dropdown, Input, Select, Spin } from "antd"
import { MenuItemType } from "antd/es/menu/hooks/useItems"
import { ItemType, MenuInfo } from "rc-menu/lib/interface"
import { ComponentProps, useMemo, useState } from "react"
import { PlusSmall } from "react-flaticons"
import "./select.scss"

export interface NDFSelectOptionType<T = unknown> extends MenuItemType {
  data?: T
}

interface Props<T> extends Omit<ComponentProps<typeof Select>, "onSelect" | "placeholder"> {
  search: string
  selectedKey?: string
  onSearch: (value: string) => void
  onSelect: (value: T) => void
  onNew?: () => void
  isLoading?: boolean
  options: NDFSelectOptionType<T>[]
  placeholder?: { notFound: string; search: string }
}

const NDFSelect = <T,>(props: Props<T>) => {
  const [open, setOpen] = useState(false)
  const {
    search,
    onSearch,
    onSelect,
    options,
    selectedKey,
    onNew,
    isLoading,
    placeholder = { notFound: "Aucun résultat", search: "Recherchez" },
    className,
    ...rest
  } = props

  const notFound = (): ItemType => {
    if (isLoading && search.length >= 3) {
      return {
        key: "no-results",
        label: <Spin />,
        disabled: true,
      }
    }

    return {
      key: "no-results",
      label: search.length >= 3 ? placeholder.notFound : placeholder.search,
      disabled: true,
    }
  }

  const onItemSelect = (selectedItem: MenuInfo) => {
    setOpen(false)
    const item = options.find((o) => o.key == selectedItem.key)?.data

    if (item) {
      onSelect(item)
    }
  }

  const items = useMemo((): ItemType[] => {
    if (options.length === 0 || isLoading) {
      return [notFound()]
    }
    return options.map((option) => {
      return {
        key: option.key,
        label: option.label,
        data: option.data,
      }
    })
  }, [options, isLoading, search])

  return (
    <>
      <Dropdown
        {...rest}
        className={className}
        open={open}
        onOpenChange={(open) => setOpen(open)}
        dropdownRender={(menu) => (
          <div className='ant-dropdown-menu ndf-select-dropdown'>
            {menu}
            {onNew && (
              <>
                <Divider className='select-divider' />
                <Button
                  className='select-add-new-button'
                  type='text'
                  icon={<PlusSmall />}
                  onClick={() => {
                    setOpen(false)
                    onNew()
                  }}
                >
                  Créer un nouveau
                </Button>
              </>
            )}
          </div>
        )}
        menu={{
          items: items,
          selectable: true,
          selectedKeys: [selectedKey ?? ""],
          onClick: (item) => onItemSelect(item),
        }}
        trigger={["click"]}
        rootClassName=''
      >
        <Input
          className='ndf-select'
          placeholder='Rechercher'
          onInput={(e) => {
            setOpen(true)
            onSearch(e.currentTarget.value)
          }}
          value={search}
          suffix={<DownOutlined />}
        />
      </Dropdown>
    </>
  )
}

export default NDFSelect
