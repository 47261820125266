import { useState } from "react"
import { DevisForm } from "../components/DevisForm"
import { DevisTable } from "../components/DevisTable"

export const Devis: React.FC = () => {
  const [showForm, setShowForm] = useState(false)
  const [selectedDevisId, setSelectedDevisId] = useState<number>()

  return (
    <div>
      <DevisTable
        refresh={showForm}
        onNew={() => setShowForm(true)}
        onEdit={(devisId) => {
          setSelectedDevisId(devisId)
          setShowForm(true)
        }}
      />
      <DevisForm
        id={selectedDevisId}
        isOpen={showForm}
        onClose={() => {
          setShowForm(false)
          setSelectedDevisId(undefined)
        }}
        onSubmit={() => {
          setShowForm(false)
          setSelectedDevisId(undefined)
        }}
      />
    </div>
  )
}
