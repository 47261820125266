import { Avatar, Button, notification } from "antd"
import { useContext, useState } from "react"
import { SuiviAPI } from "../../../../../../services/Suivi.api"
import { useUser } from "../../../../../../stores/User.store"
import { ISuivi } from "../../../../../../types/ISuivi"
import { ActivityContext } from "../FormActivity/FormActivity"
import { FormActivityInput } from "../FormActivityInput/FormActivityInput"
import "./formActivityEntry.scss"

interface IFormHistoryProps {
  entry: ISuivi
  readonly: boolean
  submit: (comment: string) => void
}

export const FormActivityEntry: React.FC<IFormHistoryProps> = ({ entry, readonly }) => {
  const [edit, setEdit] = useState(false)
  const activity = useContext(ActivityContext)
  const user = useUser()

  const deleteComment = (id: number) => {
    SuiviAPI.deleteComment(id)
      .then(() => {
        activity.loadData()
      })
      .catch(() => {
        notification.error({
          message: "Erreur",
          description: "Impossible de supprimer le commentaire",
        })
      })
  }

  const updateComment = (text: string) => {
    setEdit(false)
    SuiviAPI.updateComment(entry.IDSuivi, text)
      .then(() => {
        activity.loadData()
      })
      .catch(() => {
        notification.error({
          message: "Erreur",
          description: "Impossible de modifié le commentaire",
        })
      })
  }

  return (
    <div className='form-activity-entry'>
      <div className='activity-col'>
        <div className='activity-row'>
          <Avatar size={48} src={entry.avatar} />
          <div className='entry activity-col'>
            {edit ? (
              <FormActivityInput
                submit={updateComment}
                comment={entry.eventContent}
                cancel={() => setEdit(false)}
              />
            ) : (
              <div className='activity-content'>
                <div className='header activity-row'>
                  <div className='activity-flex'>
                    <div>
                      <strong>{entry.creatorName} </strong>
                      {entry.eventTitle}
                    </div>
                  </div>
                  <div className='activity-flex date'>
                    <div>
                      {entry.isUpdated && "Modifié"} {entry.eventDate}
                    </div>
                  </div>
                </div>
                <div
                  className='entry'
                  dangerouslySetInnerHTML={{ __html: entry.eventContent }}
                ></div>
                {!entry.deleted &&
                  !readonly &&
                  entry.isDeletable > 0 &&
                  entry.creatorId === user.IDUser && (
                    <div className='comment-actions activity-flex'>
                      <Button
                        type='link'
                        size='small'
                        onClick={() => {
                          setEdit(!edit)
                        }}
                      >
                        <p className='edit'>Modifier</p>
                      </Button>
                      <p className='separator'>•</p>
                      <Button type='link' size='small' onClick={() => deleteComment(entry.IDSuivi)}>
                        <p className='delete'>Supprimer</p>
                      </Button>
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
