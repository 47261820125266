import { Col, notification, Popconfirm } from "antd"
import { MenuInfo } from "rc-menu/lib/interface"
import { useContext, useMemo, useState } from "react"
import { ContactAPI } from "../../../../services/Contacts.api"
import { useContacts } from "../../../../stores/Contact.store"
import { ESuiviObject } from "../../../../types/ESuiviObject"
import { IContact } from "../../../../types/IContact"
import { IContactForm } from "../../../../types/IContactForm"
import IFormProps from "../../../../types/IFormProps"
import { AddressTable } from "../../../shared/form/components/address/AddressTable"
import { AddressWithInfos } from "../../../shared/form/components/address/AddressWithInfos"
import { InternalNotes } from "../../../shared/form/components/items/InternalNotes"
import { NDFForm } from "../../../shared/form/pages/Form/Form"
import { ContactContext } from "../../pages/Contacts"
import { LinkedContacts } from "../LinkedContacts/LinkedContacts"
import "./contactForm.scss"
import { ContactFormContent } from "./ContactFormContent"

export const ContactForm: React.FC<IFormProps<IContact>> = ({ isOpen, onClose, onSubmit }) => {
  const [loading, setLoading] = useState(true)
  const { id: contactId, setId: setContactId } = useContext(ContactContext)
  const contacts = useContacts()
  const isEdition = !!contactId

  const formSubModuleTabs = useMemo(() => {
    const tabs = []
    tabs.push({
      label: "Adresses",
      content: isEdition ? (
        <AddressTable contactId={contactId} />
      ) : (
        <Col xs={24} lg={18} xl={12}>
          <AddressWithInfos />
        </Col>
      ),
    })
    if (isEdition) {
      tabs.push({ label: "Personnes à contacter", content: <LinkedContacts tiersId={contactId} /> })
    }
    tabs.push({ label: "Notes Internes", content: <InternalNotes /> })
    return tabs
  }, [contactId, isEdition])

  const onFormSubmit = async (contactForm: IContactForm) => {
    contactForm.denomination = contactForm.denomination ?? ""
    contactForm.nom = contactForm.nom ?? ""
    contactForm.prenom = contactForm.prenom ?? ""

    contactForm.tags?.forEach((tag: number) => {
      // @ts-expect-error stop complaining
      contactForm[`tags[${tag}]`] = tag
    })
    delete contactForm.tags

    if (isEdition) {
      return ContactAPI.updateContact({ ...contactForm, IDTiers: contactId })
        .then((res) => {
          if (res.ok) {
            onSubmit(res.data)
            notification.success({
              message: "Succès",
              description: `Contact ${res.data.denomination || `${res.data.prenom} ${res.data.nom}`} mis à jour avec succès`,
            })
            return true
          }
          return false
        })
        .catch((err) => {
          notification.error({
            message: "Erreur",
            description: `Impossible de mettre à jour le contact. Erreur: ${JSON.stringify(err)}`,
          })
          return false
        })
    }

    return ContactAPI.createContact(contactForm)
      .then((res) => {
        if (res.ok) {
          onSubmit(res.data)
          notification.success({
            message: "Succès",
            description: `Contact ${res.data.denomination || `${res.data.prenom} ${res.data.nom}`} créé avec succès`,
          })
          return true
        }
        return false
      })
      .catch((err) => {
        notification.error({
          message: "Erreur",
          description: `Impossible de créer le contact. Erreur: ${JSON.stringify(err)}`,
        })
        return false
      })
  }

  // TODO: This depends if we only need a delete action or multiple actions
  const onActionClick = (action: MenuInfo) => {
    switch (action.key) {
      case "1":
        // console.log('supprimer')
        // if (contactId) {
        //   ContactAPI.deleteContact(contactId)
        //   onClose()
        // }
        break
      default:
        break
    }
  }

  const deleteContact = () => {
    if (isEdition && contactId) {
      ContactAPI.deleteContact(contactId)
      onClose()
    }
  }

  const formActions = [
    {
      label: (
        <Popconfirm
          title='Supprimer le contact'
          description='Êtes-vous sûr de vouloir supprimer ce contact ?'
          onConfirm={deleteContact}
          okText='Oui'
          cancelText='Non'
          okType='danger'
          icon={null}
        >
          Supprimer
        </Popconfirm>
      ),
      key: "1",
      danger: true,
    },
  ]

  return (
    <NDFForm
      title={isEdition ? "Mise à jour" : "Nouveau contact"}
      onClose={onClose}
      onSubmit={onFormSubmit}
      next={() => {
        const currentContactIndex = contacts.findIndex((c) => c.IDTiers === contactId)
        const contact =
          contacts.length === currentContactIndex + 1
            ? contacts.at(0)
            : contacts.at(currentContactIndex + 1)
        if (contact) {
          setContactId(contact.IDTiers)
        }
      }}
      previous={() => {
        const nextContactIndex = contacts.findIndex((c) => c.IDTiers === contactId) - 1
        const contact = contacts.at(nextContactIndex)
        if (contact) {
          setContactId(contact.IDTiers)
        }
      }}
      isOpen={isOpen}
      subModuleTabs={formSubModuleTabs}
      loading={loading}
      actions={isEdition ? formActions : []}
      onActionClick={onActionClick}
      objectId={contactId}
      activityType={ESuiviObject.CONTACT}
    >
      <ContactFormContent id={contactId} onLoad={setLoading} />
    </NDFForm>
  )
}
