import { Form, Modal } from "antd"
import { useEffect, useState } from "react"
import { ISearchContactAddress } from "../../../../../types/IContact"
import { AddressPicker } from "../../../../shared/form/components/items/AddressPicker"
import NDFSelect from "../../../../shared/form/components/select/NDFSelect"
import "./addressSelectModal.scss"

interface IAddressModalProps {
  open: boolean
  title: string
  addresses: ISearchContactAddress[]
  currentAddress?: ISearchContactAddress
  onClose: (address?: ISearchContactAddress) => void
}

const AddressSelectModal: React.FC<IAddressModalProps> = ({
  open,
  addresses,
  onClose,
  currentAddress,
  title,
}) => {
  const [form] = Form.useForm()
  const [search, setSearch] = useState("")
  const [selectedAddress, setSelectedAddress] = useState({} as ISearchContactAddress)

  useEffect(() => {
    setForm(selectedAddress)
  }, [selectedAddress])

  useEffect(() => {
    if (currentAddress) {
      setForm(currentAddress)
      setSelectedAddress(currentAddress)
    }
  }, [addresses, currentAddress, open])

  const setForm = (address: ISearchContactAddress) => {
    setSearch(`${address.addressName} - ${address.formattedAddress}`)
    form.setFieldsValue({
      formattedAddress: address.formattedAddress,
      address: address.address,
      codePostal: address.codePostal,
      ville: address.ville,
      countryName: address.countryName,
      countryCode: address.countryCode,
    })
  }

  const handleClose = (address?: ISearchContactAddress) => {
    onClose(address)
    setTimeout(() => {
      form.resetFields()
    }, 500)
  }

  return (
    <Modal
      title={`Sélectionner l'${title}`}
      open={open}
      onCancel={() => handleClose()}
      onOk={() => handleClose(selectedAddress)}
      okText='Sélectionner'
      cancelText='Annuler'
      className='address-select-modal'
      width={"100%"}
    >
      <NDFSelect<ISearchContactAddress>
        options={addresses.map((address) => ({
          key: address.IDAddress,
          label: `${address.addressName} - ${address.formattedAddress}`,
          data: address,
        }))}
        search={search}
        onSearch={(search) => setSearch(search)}
        onSelect={(value) => {
          setSelectedAddress(value)
        }}
        selectedKey={String(selectedAddress.IDAddress)}
        placeholder={{
          notFound: "Aucune adresse trouvé",
          search: "Sélectionnez une adresse",
        }}
      />
      <Form
        form={form}
        labelCol={{ xs: 24, sm: 5, md: 6, lg: 5, xl: 7, xxl: 6 }}
        labelWrap
        labelAlign='left'
        layout='horizontal'
        disabled={true} // TODO: this can be changed to allow editing
        className='address-details'
      >
        <AddressPicker />
      </Form>
    </Modal>
  )
}

export default AddressSelectModal
