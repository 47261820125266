import { useState } from "react"
import { ArticleForm } from "../components/ArticleForm/ArticleForm"
import { ArticleTable } from "../components/ArticleTable/ArticleTable"

export const Articles: React.FC = () => {
  const [showForm, setShowForm] = useState(false)
  const [selectedArticleId, setSelectedArticleId] = useState<number>()

  const onClose = () => {
    setShowForm(false)
    setTimeout(() => {
      setSelectedArticleId(undefined)
    }, 500)
  }

  return (
    <div>
      <ArticleTable
        refresh={showForm}
        onNew={() => setShowForm(true)}
        onEdit={(contactId) => {
          setSelectedArticleId(contactId)
          setShowForm(true)
        }}
      ></ArticleTable>
      <ArticleForm isOpen={showForm} onClose={onClose} onSubmit={onClose} id={selectedArticleId} />
    </div>
  )
}
