import { Row, Spin, Tabs } from "antd"
import { createContext, useEffect, useState } from "react"
import "react-quill/dist/quill.snow.css"
import { SuiviAPI } from "../../../../../../services/Suivi.api"
import { ESuiviObject } from "../../../../../../types/ESuiviObject"
import { ESuiviTypes } from "../../../../../../types/ESuiviTypes"
import { ISuivi } from "../../../../../../types/ISuivi"
import { FormActivityBody } from "../FormActivityBody/FormActivityBody"
import "./formActivity.scss"

interface IActivityProps {
  objectId: number | undefined
  type: ESuiviObject
}

interface Context {
  loadData: () => void
}

export const ActivityContext = createContext<Context>({ loadData: () => {} })

export const FormActivity: React.FC<IActivityProps> = ({ objectId, type }) => {
  const [activities, setActivity] = useState<ISuivi[]>([])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setActivity([])
    loadData()
  }, [objectId])

  const loadData = () => {
    if (objectId) {
      setLoading(true)
      SuiviAPI.getSuivi(ESuiviTypes.ALL, type, objectId).then((res) => {
        setActivity(res.data)
        setLoading(false)
      })
    }
  }

  const tabs = [
    {
      label: "Tout",
      content: (
        <FormActivityBody
          history={activities}
          formType={type}
          objectId={objectId}
          readonly
          loadData={loadData}
        />
      ),
    },
    {
      label: "Commentaires",
      content: (
        <FormActivityBody
          history={activities.filter((activity) => activity.eventType === ESuiviTypes.COMMENT)}
          formType={type}
          objectId={objectId}
          loadData={loadData}
        />
      ),
    },
    {
      label: "Historique",
      content: (
        <FormActivityBody
          history={activities.filter((activity) => activity.eventType === ESuiviTypes.SYSTEM)}
          formType={type}
          objectId={objectId}
          readonly
          loadData={loadData}
        />
      ),
    },
  ]

  return (
    <ActivityContext.Provider value={{ loadData }}>
      <Spin spinning={loading} >
        <Row className='form-activity bg-white'>
          <Tabs
            className='form-submodule-tabs'
            defaultActiveKey='1'
            type='card'
            items={tabs.map((tab, i) => ({
              label: tab.label,
              key: i.toString(),
              children: tab.content,
            }))}
          />
        </Row>
      </Spin>
    </ActivityContext.Provider>
  )
}
