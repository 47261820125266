import { Form } from "antd"
import { useEffect, useState } from "react"
import { ContactAPI } from "../../../../services/Contacts.api"
import { ISearchContact, SearchContact } from "../../../../types/IContact"
import { ContactForm } from "../../../contact/components/ContactForm/ContactForm"
import NDFSelect, { NDFSelectOptionType } from "../../../shared/form/components/select/NDFSelect"
import Addresses from "./Addresses/Addresses"
import "./contactSearch.scss"

interface Props {
  onSelect: (contact: ISearchContact) => void
  label: string
  value: ISearchContact | undefined
}

export const ContactSearch: React.FC<Props> = ({ onSelect, value, label }) => {
  const [contactNameIds, setContactNameIds] = useState<NDFSelectOptionType<ISearchContact>[]>([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setLoading(true)

    const timeout = setTimeout(() => {
      searchContact(search)
    }, 500)

    return () => {
      clearTimeout(timeout)
      setLoading(false)
    }
  }, [search])

  const searchContact = (search: string) => {
    if (search.length && search.length < 3) {
      return Promise.resolve()
    }
    return ContactAPI.searchForContact(search)
      .then((res) => {
        if (res.ok) {
          const contacts = res.data?.map((contact) => ({
            key: contact.IDTiers,
            label: contact.tiersDenomination,
            data: contact,
          }))
          setContactNameIds(contacts)
          return contacts
        }
        return []
      })
      .finally(() => setLoading(false))
  }

  const onContactSelect = (contact: ISearchContact) => {
    // TODO : don't make a request when selecting a contact
    setSearch(contact.tiersDenomination)
    onSelect && onSelect(contact)
  }

  return (
    <>
      <Form.Item className='contact-search' label={label}>
        <NDFSelect<ISearchContact>
          search={search}
          options={contactNameIds}
          onSearch={setSearch}
          onSelect={onContactSelect}
          selectedKey={value ? value.tiersDenomination : undefined}
          isLoading={loading}
          onNew={() => setShowModal(true)}
          placeholder={{ notFound: "Aucun contact trouvé", search: "Recherchez un contact" }}
        />
        {!!value?.address.length && <Addresses addresses={value.address} />}
      </Form.Item>
      <ContactForm
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={(contact) => {
          if (contact) {
            searchContact(contact.denomination).then((contacts) => {
              const foundContact = contacts?.find((c) => c.key === contact.IDTiers)
              onContactSelect(foundContact?.data ?? new SearchContact(contact))
            })
          }
          setShowModal(false)
        }}
      />
    </>
  )
}
