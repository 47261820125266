import { Flex, Row, Space, Spin } from "antd"
import { useEffect, useState } from "react"
import { ContactAPI } from "../../../../../services/Contacts.api"
import { ISimpleContactAddress } from "../../../../../types/IContactAddress"
import { ButtonDelete } from "../../../utils/components/ButtonDelete/ButtonDelete"
import { ButtonNew } from "../../../utils/components/ButtonNew/ButtonNew"
import { Table } from "../../../utils/components/Table/Table"
import { NoData } from "../../pages/TablePage/NoData"
import { AddressModal } from "./AddressModal"
import "./addressTable.scss"

const COLUMNS = [
  {
    title: "Nom",
    field: "addressName",
  },
  {
    title: "Complément",
    field: "complement",
  },
  {
    title: "Address",
    field: "formattedAddress",
  },
  {
    title: "Tél.",
    field: "phone",
  },
  {
    title: "Mail",
    field: "email",
  },
]

interface Props {
  contactId: number
}

export const AddressTable: React.FC<Props> = ({ contactId }) => {
  const [loading, setLoading] = useState(true)
  const [addresses, setAddresses] = useState<ISimpleContactAddress[]>([])
  const [columns, setColumns] = useState<any[]>([])
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [selectedAddressId, setSelectedAddressId] = useState<number>()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    getAddresses()
    const mapped = COLUMNS.map((col) => {
      return {
        key: col.field,
        title: col.title,
        dataIndex: col.field,
        width: `${100 / COLUMNS.length}%`,
        sorter: (a: any, b: any) => {
          const strA = String(a[col.field]).trim().toLowerCase()
          const strB = String(b[col.field]).trim().toLowerCase()
          if (strA === "") return 1
          if (strB === "") return -1
          return strA.localeCompare(strB)
        },
      }
    })
    setColumns(mapped ?? [])
  }, [])

  const getAddresses = () => {
    setLoading(true)
    ContactAPI.getAddresses(contactId).then((res) => {
      setLoading(false)
      setAddresses(
        res.data.map((contact) => ({
          ...contact,
          disableSelection: contact.pal,
          key: contact.IDAddress,
        })),
      )
    })
  }

  const deleteAddress = () => {
    const promises = selectedRows.map((row) => ContactAPI.deleteAddress(row.IDAddress))
    Promise.all(promises).then(() => {
      getAddresses()
      setSelectedRows([])
    })
  }

  return (
    <Spin spinning={loading} wrapperClassName='address-table'>
      <Flex vertical gap={15}>
        <Row justify='end'>
          <Space>
            <ButtonDelete
              onClick={deleteAddress}
              size='small'
              iconSize={15}
              className='trash-button'
            />
            <ButtonNew onClick={() => setShowModal(true)} size='small' withoutIcon />
          </Space>
        </Row>
        {addresses.length ? (
          <Table
            loading={loading}
            data={addresses}
            columnsToDisplay={columns}
            noData={() => (
              <NoData
                onNew={() => setShowModal(true)}
                pageDescription='Aucune adresse enregistrée'
                pageObjectives='Ajoutez une nouvelle adresse pour ce contact'
                onNewText='Nouvelle adresse'
              />
            )}
            allowSelection
            onRowSelection={(_, selectedRows) => setSelectedRows(selectedRows)}
            pagination={false}
            onEdit={(record: ISimpleContactAddress) => {
              setSelectedAddressId(record.IDAddress)
              setShowModal(true)
            }}
          />
        ) : (
          <NoData
            onNew={() => setShowModal(true)}
            pageDescription='Aucune adresse enregistrée'
            pageObjectives='Ajoutez une nouvelle adresse pour ce contact'
            onNewText='Nouvelle adresse'
          />
        )}
      </Flex>
      <AddressModal
        onCancel={() => {
          setShowModal(false)
          setSelectedAddressId(undefined)
        }}
        onOk={() => {
          getAddresses()
          setShowModal(false)
          setSelectedAddressId(undefined)
        }}
        open={showModal}
        addressId={selectedAddressId}
        idContact={contactId}
      />
    </Spin>
  )
}
