import { Descriptions, Flex } from "antd"
import { DescriptionsItemType } from "antd/es/descriptions"
import { useMemo } from "react"
import { useDevisContext } from "../../context/devis.context"

export const TotalDetails = () => {
  const { lines } = useDevisContext()

  /**
   * Sum the total price of each tax by name
   *
   * @returns an object with the sum of the total price of each tax by name
   */
  const sumTotalsByTaxName = () => {
    return lines.reduce((acc: { [key: string]: number }, line) => {
      if (line.isTitle || line.tax.taxeCoeff === 0) {
        return acc
      }
      const taxName = `${line.tax.taxeName} (${line.tax.taxeCoeff}%)`
      const price =
        (line.price * line.quantity - line.discount.calculateDiscount(line.price * line.quantity)) *
        (line.tax.taxeCoeff / 100)
      acc[taxName] = acc[taxName] ? acc[taxName] + price : price
      return acc
    }, {})
  }

  const priceDetails: DescriptionsItemType[] = useMemo(() => {
    const subTotal = lines
      .filter((line) => !line.isTitle)
      .map(
        (line) =>
          line.price * line.quantity - line.discount.calculateDiscount(line.price * line.quantity),
      )
      .reduce((acc, curr) => acc + curr, 0)

    const taxAmountByName = sumTotalsByTaxName()

    const cumulativeTaxAmount = Object.values(taxAmountByName).reduce((acc, curr) => acc + curr, 0)
    const total = subTotal + cumulativeTaxAmount

    const details: DescriptionsItemType[] = [getSubTotalLine(subTotal)]

    if (Object.values(taxAmountByName).length > 0) {
      details.push(getTaxDetailsLine(taxAmountByName))
    }

    return [...details, getTotalLine(total)]
  }, [lines])

  return <Descriptions bordered column={{ xs: 1, sm: 2 }} items={priceDetails} />
}

const getSubTotalLine = (subTotal: number) => {
  return {
    label: "Sous-total",
    children: `€ ${subTotal.toFixed(2)}`,
    span: 5,
  }
}

const getTaxDetailsLine = (taxSum: { [key: string]: number }) => {
  const taxNames = (
    <Flex vertical gap={5}>
      {Object.keys(taxSum).map((taxName, i) => (
        <div key={`tax-name-${i}`}>{taxName}</div>
      ))}
    </Flex>
  )
  const taxValues = (
    <Flex vertical gap={5}>
      {Object.values(taxSum).map((tax, i) => (
        <div key={`tax-value-${i}`}>{`€ ${tax.toFixed(2)}`}</div>
      ))}
    </Flex>
  )

  return {
    label: taxNames,
    children: taxValues,
    span: 4,
  }
}

const getTotalLine = (total: number) => {
  return {
    label: "TOTAL (€)",
    children: `€ ${total.toFixed(2)}`,
    span: 4,
  }
}
